import { graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import HomePageLanding from "../components/Homepage/HomePageLanding"
import InfoSection from "../components/Homepage/InfoSection"
import NewsSection from "../components/Homepage/NewsSection"
import SEO from "../components/seo"
import { useNav } from "../context"
import { useLanguage } from "../context/LanguageContext"
import { useSpecialPage } from "../hooks/useSpecialPage.hook"
import { Banner } from "../utils/imageUpload"
import Layout from "../components/layout"

const IndexPage = ({ data }) => {
  const { checkIntersection, isSpecial, makeSpecial } = useNav()
  useSpecialPage(true)

  const [main] = data.allSanityHomepage.edges.map(({ node }) => node)

  const files = data.allSanityFiles.edges.map(({ node }) => node)

 

  const { language } = useLanguage()


  function chooseText(path, mainObj = main, language = "de") {
    return mainObj[`${path}_${language}`]
  }

  return (
    <Layout>
      <SEO title="Home" />
      <HomePageLanding main={main} />
      <InfoSection main={main} />
      <NewsSection
        fileArr={files}
        bg="var(--beige)"
        home={true}
        btnText={chooseText("buttonAllArticles", main)}
      />
    </Layout>
  )
}

export default IndexPage

const Landing = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 75vh;
  position: relative;
  text-align: center;
  @media (min-width: 1400px) {
    min-height: 60rem;
  }
  .content {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h1 {
    margin: 0 16rem 3.2rem;
  }

  p {
    margin: 0 24rem 4rem;
  }

  background-image: url(${Banner});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (max-width: 1440px) {
    h1 {
      width: 100%;
      margin-bottom: 3.2rem;
      /* margin: 0 auto 3.2rem !important; */
    }
    p {
      width: 100%;
      margin-bottom: 4rem;
    }
  }
`

export const query = graphql`
  query {
    allSanityHomepage {
      edges {
        node {
          headline_en
          headline_de
          body_de
          body_en
          explanation_de
          explanation_en
          explanationSub_de
          explanationSub_en
          linkArticle_de
          linkArticle_en
          linkAbout_en
          linkAbout_de
          buttonAllArticles_de
          buttonAllArticles_en
          buttonServices_en
          buttonServices_de
          secondHeadline_de
          newestArticle_en
          newestArticle_de
          secondHeadline_en
          servicesList_de
          servicesList_en
          thirdHeadline_en
          thirdHeadline_de
          mainImage {
            asset {
              fluid {
                src
                ...GatsbySanityImageFluid
              }
            }
          }
          mobileImage {
            asset {
              fluid {
                src
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    allSanityFiles(sort: { fields: date, order: DESC }, limit: 3) {
      edges {
        node {
          file {
            asset {
              url
            }
          }
          title
          size
          category {
            title
          }
          date(formatString: "MMM YY")
        }
      }
    }
  }
`
