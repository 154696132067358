import React from "react"
import styled from "styled-components"
import { OutlinedButtons } from "../../styles/Buttons"
import { Link } from "gatsby"
import { chooseText } from "../../utils/chooseText"
import { ContentWrapper } from "../../styles/Wrapper"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { useLanguage } from "../../context/LanguageContext"

const NewsSection = ({ fileArr, bg, home, btnText }) => {
  const { language } = useLanguage()

  return (
    <News bg={bg} home={home}>
      <ContentWrapper style={{ width: home ? "" : "100%" }}>
        <div className="main" home={home && home.toString()}>
          <h3 className="title">
            {language === "de"
              ? "Aktuellste Steuernachrichten"
              : "Latest Tax News"}
          </h3>

          <div className="tax-news-container">
            {fileArr.slice(0, 3).map((file, i) => {
              return (
                <div
                  data-sal="fade"
                  data-sal-duration="600"
                  data-sal-delay="100"
                  data-sal-easing="ease"
                  key={i}
                  className="item"
                >
                  <h4> {file.title}</h4>
                  <a href={file.file.asset.url}>
                    <h5 className="links" style={{ color: "var(--blue)" }}>
                      Download PDF →{" "}
                    </h5>
                  </a>
                </div>
              )
            })}
          </div>

          {home && (
            <AniLink
              bg="var(--beige)"
              // cover
              direction="right"
              className="btn-link"
              to="/documents"
            >
              <OutlinedButtons>
                {language === "de" ? "Alle Steuernews" : "All Tax News"}
              </OutlinedButtons>
            </AniLink>
          )}
        </div>
      </ContentWrapper>
    </News>
  )
}

export default NewsSection

const News = styled.div`
  .main {
    padding: ${props => (props.home ? "80px 160px" : "40px 80px")};
    @media (max-width: 1440px) {
      padding: ${props => (props.home ? "8rem 0" : "0")};
    }

    @media (max-width: 1024px) {
      padding: ${props => (props.home ? "8rem 7rem" : "0")};
      display: flex;
      flex-direction: column;
    }

    @media (max-width: 600px) {
      padding: ${props => (props.home ? "8rem 0" : "0")};
    }
  }

  h3 {
    @media (max-width: 1024px) {
      margin-bottom: 3.2rem;
    }
  }

  background-color: ${props => props.bg};

  .tax-news-container {
    display: flex;
    justify-content: space-evenly;

    @media (max-width: 1024px) {
      flex-direction: column;
    }

    .links {
      margin-top: 14px;
      @media (max-width: 1024px) {
        margin-top: 1.6rem;
      }
    }

    .item {
      width: 30.3%;
      padding-top: 16px;
      margin-top: 32px;
      border-top: 2px solid #333333;
      margin-right: 32px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media (max-width: 1024px) {
        width: 100%;
        padding: 0;
        margin: 0;
        padding: 1.6rem 0 4rem;
      }
    }

    .item:last-child {
      @media (max-width: 1024px) {
        padding-bottom: 0;
      }
    }
  }

  button {
    margin: 4rem auto 0;

    display: flex;
    justify-content: center;
    @media (max-width: 1024px) {
      margin: 3.2rem auto 0;
    }
  }

  // padding: 80px 160px;
`
