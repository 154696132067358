import React from "react"
import { Link } from "gatsby"
import { chooseText } from "../../utils/chooseText"
import { ContentWrapper } from "../../styles/Wrapper"
import styled from "styled-components"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { useLanguage } from "../../context"

const InfoSection = ({ main }) => {
  const { language } = useLanguage()
  return (
    <Info>
      <ContentWrapper>
        <div
          data-sal="fade"
          data-sal-duration="600"
          data-sal-delay="100"
          data-sal-easing="ease"
          className="container-text"
        >
          <h3>{chooseText("secondHeadline", main, language)}</h3>
          <p className="body">{chooseText("body", main, language)}</p>
          <AniLink
            // cover
            direction="down"
            bg="var(--beige)"
            to="/about"
            className="links"
          >
            {chooseText("linkAbout", main, language)} →
          </AniLink>
        </div>
      </ContentWrapper>
    </Info>
  )
}

export default InfoSection

const Info = styled.div`
  background-color: var(--backgrounds);
  text-align: center;

  .container-text {
    padding: 13rem 24rem;

    a {
      color: var(--blue);
      margin: 24px 0 16px 0;
    }
    h3 {
      margin-bottom: 2.4rem;
    }
    p {
      margin-bottom: 1.6rem;
    }
  }

  @media (max-width: 1440px) {
    .container-text {
      padding: 13rem 8rem 8rem;
    }
  }

  @media (max-width: 1024px) {
    .container-text {
      padding: 13rem 7.2rem 8rem;
    }
  }

  @media (max-width: 600px) {
    .container-text {
      padding: 13rem 0 8rem;
    }
  }
`
