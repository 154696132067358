import React from "react"
import styled from "styled-components"
import { useLanguage, useNav } from "../../context"
import { ContentWrapper } from "../../styles/Wrapper"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import ServicesBar from "../templates/ServicesBar"
import Observer from "@researchgate/react-intersection-observer"
import { OutlinedButtons } from "../../styles/Buttons"
import { BannerPhone, Banner } from "../../utils/imageUpload"
import BackgroundImage from "gatsby-background-image"
import { useDisplaySize } from "../../hooks/displaySize.hook"

const HomePageLanding = ({ main }) => {
  const { language } = useLanguage()
  const { checkIntersection } = useNav()

  function chooseText(path, mainObj = main) {
    return mainObj[`${path}_${language}`]
  }

  const { display } = useDisplaySize()
  const image =
    display === "S" ? main.mobileImage.asset.fluid : main.mainImage.asset.fluid

  const BgImg = (
    <div className="fw">
      <BackgroundImage
        fluid={image}
        Tag="div"
        className="background"
        style={{ height: "100%", width: "100%", filter: "brightness(40%)" }}
      />
    </div>
  )

  return (
    <Landing
      data-sal="fade"
      data-sal-duration="600"
      data-sal-delay="100"
      data-sal-easing="easeInSine"
    >
      <ContentWrapper>
        <div
          data-sal="fade"
          data-sal-duration="600"
          data-sal-delay="500"
          data-sal-easing="easeInSine"
          className="content"
        >
          <Observer rootMargin="-100px 0% -25%" onChange={checkIntersection}>
            <h1>{chooseText("headline")}</h1>
          </Observer>
          <p className="body">{chooseText("explanation")}</p>
          <p className="body">{chooseText("explanationSub")}</p>
        </div>

        <AniLink to="/contact" bg="var(--beige)">
          <OutlinedButtons
            data-sal="fade"
            data-sal-duration="600"
            data-sal-delay="500"
            data-sal-easing="easeInSine"
            white
          >
            {chooseText("buttonServices")}
          </OutlinedButtons>
        </AniLink>
        <ServicesBar servicesList={main} />
      </ContentWrapper>
      {BgImg}
    </Landing>
  )
}

export default HomePageLanding

const Landing = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 80vh;
  position: relative;
  text-align: center;
  @media (min-width: 1400px) {
    min-height: 60rem;
  }
  .content {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h1 {
    margin: 0 16rem 3.2rem;
  }

  p {
    margin: 0 24rem 4rem;
  }

  .fw {
    position: fixed;
    height: inherit;
    width:100%;
    /* background-image: url(${Banner}); */
    z-index:-1;
    .background {
    /* background-repeat: no-repeat;
    background-size: cover;
    background-position: center; */
    width:100%;
    background:100%


    }

    /* @media (max-width: 1024px) {
    /* max M */

    /* background-image: url(${BannerPhone}); */
  /* } */ 
  }


  /* background-image: url(${Banner}); */

  @media (max-width: 1024px) {
    /* max M */

    /* background-image: url(${BannerPhone}); */
  }

  @media (max-width: 1440px) {
    h1 {
      width: 100%;
      margin-bottom: 3.2rem;
      /* margin: 0 auto 3.2rem !important; */
    }
    p {
      width: 100%;
      margin-bottom: 4rem;
    }
  }
`
